export enum ROUTES {
  AUTH = '/auth',
  LOGIN = `${AUTH}/login`,
  REGISTER = `${AUTH}/register`,
  RESET_PASSWORD = `${AUTH}/reset-password`,

  DASHBOARD = '/dashboard',

  SETTINGS = '/settings',
  ADD_ORG = '/tenant',

  // Maintenance
  MAINTENANCE = '/maintenance',

  // Tables
  ORGANIZATIONS = '/tenants',
  ISSUES = '/issues',
  ASSETS = '/assets',
  DNS = '/dns',
  TECHNOLOGIES = '/technologies',
  PATCHING = '/patching',
  CREDENTIALS = '/credentials',
}

export const DEFAULT_APP_ROUTES = {
  ASSETS: `${ROUTES.ASSETS}/all`,
  ISSUES: `${ROUTES.ISSUES}/table`,
  DASHBOARD: ROUTES.DASHBOARD,
  SETTINGS: `${ROUTES.SETTINGS}/environment`,
  ADD_ORG: `${ROUTES.ADD_ORG}/environment`,
}
