import { ComponentProps, Fragment, ReactNode, SyntheticEvent, useMemo, useState } from 'react'
import TooltipElement from '@components/Common/TooltipElement'
import { MenuItem, Popover, PopoverClasses, PopoverOrigin } from '@mui/material'
import clsx from 'clsx'
import ChevronRight from '@assets/Common/chevron-right.svg?react'
import StyledTextField from '@components/Common/StyledTextField'

export type BasePopoverItem = {
  name: string
  value?: any
  icon?: ReactNode
  tooltipProps?: Omit<ComponentProps<typeof TooltipElement>, 'children'>
  disabled?: boolean
  onClick?: (e: SyntheticEvent, opt: BasePopoverItem) => void
}

type PopoverElementProps<TItem> = {
  list: TItem[]
  onOptionClick?: (e: SyntheticEvent, item: TItem) => void // Either this or onClick in the item
  handleCloseMenu: (e: SyntheticEvent) => void
  anchorEl: HTMLElement

  anchorPositions?: { anchorOrigin?: PopoverOrigin; transformOrigin?: PopoverOrigin }
  isOptionActive?: (item: TItem) => boolean
  search?: boolean
  size?: 'small' | 'medium'
  customPopoverClass?: string
  showChevronOnOption?: (item: TItem) => boolean
  darkMode?: boolean
  beforeListChildren?: ReactNode
  afterListChildren?: ReactNode
  useAnchorWidth?: boolean
}

const TRANSITION_TIME = 200

export const PopoverElement = <TItem extends BasePopoverItem>({
  list,
  onOptionClick,
  anchorEl,
  handleCloseMenu,

  anchorPositions = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  },

  showChevronOnOption,
  search,
  useAnchorWidth,
  size,
  customPopoverClass,
  darkMode,
  beforeListChildren,
  afterListChildren,
  isOptionActive,
}: PopoverElementProps<TItem>) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const filteredList = useMemo(() => {
    const defaultList = list || []
    if (!search) return defaultList

    const filtered = defaultList.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))

    return filtered
  }, [searchValue, search, list])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value
    setSearchValue(searchVal)
  }

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation()
    handleCloseMenu(e)
  }

  return (
    <Popover
      transitionDuration={TRANSITION_TIME}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClick={(e) => e.stopPropagation()}
      onClose={(e) => {
        handleClose(e as SyntheticEvent)

        if (search) {
          setTimeout(() => {
            setSearchValue('')
          }, TRANSITION_TIME)
        }
      }}
      classes={
        {
          root: 'popover-element',
          paper: clsx('popover-element__paper', customPopoverClass, {
            dark: darkMode,
            [size]: size,
          }),
          list: 'list-wrap',
        } as Partial<PopoverClasses>
      }
      slotProps={{
        paper: {
          style: {
            width: useAnchorWidth ? anchorEl?.clientWidth : 'auto',
            minWidth: useAnchorWidth && 'unset',
          },
        },
      }}
      {...anchorPositions}
    >
      {beforeListChildren}

      {search && (
        <StyledTextField
          autoFocus
          value={searchValue}
          onChange={handleSearch}
          placeholder='Search'
          withStartSearchIcon
          fullWidth
          cssVariablesProps={{
            borderRadius: 'var(--br-md)',
          }}
        />
      )}

      <div className='list-items'>
        {filteredList.map((option, idx) => {
          const Icon = option.icon

          return (
            <TooltipElement {...option.tooltipProps} key={idx}>
              <div className='list-item--wrapper' onClick={(e) => e.stopPropagation()}>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    if (option.onClick) option.onClick(e, option)
                    if (onOptionClick) onOptionClick(e, option)
                  }}
                  disabled={option.disabled}
                  selected={isOptionActive && isOptionActive(option)}
                  classes={{ root: 'list-item', selected: 'list-item--selected' }}
                >
                  <Fragment>
                    {Icon && <div className='list-item__icon'>{Icon}</div>}
                    {option.name}

                    {showChevronOnOption && showChevronOnOption(option) && (
                      <ChevronRight className='list-item__chevron' />
                    )}
                  </Fragment>
                </MenuItem>
              </div>
            </TooltipElement>
          )
        })}
      </div>

      {afterListChildren}
    </Popover>
  )
}
