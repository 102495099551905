import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import React, { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import './AuthContainer.scss'

type AuthContainerProps = {
  children: React.ReactNode
  className?: string
  flexContainerProps?: Partial<ComponentProps<typeof FlexContainer>>
}

export const AuthContainer: FC<AuthContainerProps> = ({ children, className, flexContainerProps }) => {
  return (
    <FlexContainer className={clsx('auth-container', className)} {...flexContainerProps}>
      {children}
    </FlexContainer>
  )
}
