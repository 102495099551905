import { returnData } from '@services/http-helpers'
import { axiosInstance } from '@services/axios'
import { AUTH_ROUTES } from '../services-consts'
import { LoginSchemeType } from '@yup/login'
import {
  EnableMFAResp,
  GetEnableMFADataResp,
  LoginToMFAResp,
  PreLoginResp,
  RegisterPayload,
  ResetPwdPayload,
  TokenChecksResp,
  VerifyMFAPayload,
} from '@typings/auth'
import { MessageResponse } from '@typings/generics'
import { addUserScheme } from '@yup/add-user'
import { InferType } from 'yup'

const getAuthenticatedEnableMfaData = async () => {
  return axiosInstance.get<GetEnableMFADataResp>(AUTH_ROUTES.ENABLE_MFA_AUTHENTICATED).then(returnData)
}

const getUnauthenticatedEnableMfaData = async (token: string) => {
  return axiosInstance
    .get<GetEnableMFADataResp>(AUTH_ROUTES.ENABLE_MFA, { params: { mfa_token: token } })
    .then(returnData)
}

const enableUnauthenticatedMFA = async (token: string, mfa_secret: string) => {
  return axiosInstance
    .post<EnableMFAResp>(AUTH_ROUTES.ENABLE_MFA, { mfa_token: token, mfa_secret })
    .then(returnData)
}

const enableAuthenticatedMFA = async (mfa_secret: string) => {
  return axiosInstance
    .post<EnableMFAResp>(AUTH_ROUTES.ENABLE_MFA_AUTHENTICATED, { mfa_secret })
    .then(returnData)
}

class AuthServiceClass {
  preLogin = async (email: string) => {
    return axiosInstance.post<PreLoginResp>(AUTH_ROUTES.PRELOGIN, { email }).then(returnData)
  }

  login = async (payload: LoginSchemeType): Promise<MessageResponse | LoginToMFAResp> => {
    return axiosInstance.post(AUTH_ROUTES.LOGIN, { ...payload, VALIDATE_MFA: false }).then(returnData)
  }

  register = async (payload: RegisterPayload): Promise<MessageResponse> => {
    return axiosInstance.post(AUTH_ROUTES.REGISTER, payload).then(returnData)
  }

  resetPwd = async (payload: ResetPwdPayload): Promise<MessageResponse> => {
    return axiosInstance.post(AUTH_ROUTES.RESET_PWD, payload).then(returnData)
  }

  logout = async (): Promise<MessageResponse> => {
    return axiosInstance.post(AUTH_ROUTES.LOGOUT).then(returnData)
  }

  checkSession = async (): Promise<MessageResponse> => {
    return axiosInstance.get(AUTH_ROUTES.CHECK_SESSION).then(returnData)
  }

  checkInvitationToken = async (token: string) => {
    return axiosInstance
      .get<TokenChecksResp>(AUTH_ROUTES.CHECK_INVITATION_TOKEN, { params: { token } })
      .then(returnData)
  }

  checkResetPwdToken = async (token: string) => {
    return axiosInstance
      .get<TokenChecksResp>(AUTH_ROUTES.CHECK_RESET_PWD_TOKEN, { params: { token } })
      .then(returnData)
  }

  enableMFA = async (token: string, mfa_secret: string) => {
    return token ? enableUnauthenticatedMFA(token, mfa_secret) : enableAuthenticatedMFA(mfa_secret)
  }

  getEnableMfaData = async (token: string | null) => {
    return token ? getUnauthenticatedEnableMfaData(token) : getAuthenticatedEnableMfaData()
  }

  verifyMFA = async (payload: VerifyMFAPayload) => {
    return axiosInstance.post<MessageResponse>(AUTH_ROUTES.VERIFY_MFA, payload).then(returnData)
  }

  inviteUser = async (orgId: number, newUser: InferType<typeof addUserScheme>) => {
    return axiosInstance.post<MessageResponse>(AUTH_ROUTES.INVITE(orgId), newUser).then(returnData)
  }

  reInviteUser = async (orgId: number, userId: number) => {
    return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REINVITE(orgId, userId)).then(returnData)
  }

  requestPasswordReset = async (orgId: number, userId: number) => {
    return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REQ_PWD_RESET(orgId, userId)).then(returnData)
  }

  requestMFAReset = async (orgId: number, userId: number) => {
    return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REQ_MFA_RESET(orgId, userId)).then(returnData)
  }

  removeAuthenticatedMfa = async () => {
    return axiosInstance.delete<MessageResponse>(AUTH_ROUTES.MFA_DATA).then(returnData)
  }
}

export const AuthService = new AuthServiceClass()
