import Modal from '@components/Common/Modal'
import { MFAProps, MFAStep, MFA } from '@pages/Auth/components/MFA/MFA'
import { useCommonStore } from '@zustand/useCommonStore'
import { FC, useState } from 'react'
import KeyIcon from '@assets/SideMenu/credentials-icon.svg?react'
import { useUserStore } from '@zustand/useUserStore'

export const EnableMFAModal: FC = () => {
  const [mfaProps, setMfaProps] = useState<MFAProps>({ step: MFAStep.ENABLE, token: null })

  const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)
  const { user, setShowEnableMFAModal, showEnableMFAModal, setUser } = useUserStore((state) => ({
    user: state.user,
    setShowEnableMFAModal: state.setShowEnableMFAModal,
    showEnableMFAModal: state.showEnableMFAModal,
    setUser: state.setUser,
  }))

  const onBackupAcknowledge = () => {
    setShowEnableMFAModal(false)
    toggleSnackBar('Multi-Factor Authentication enabled successfully', { type: 'success' })
    setUser({ ...user, mfa_enabled: true })

    setTimeout(() => {
      setMfaProps({ step: MFAStep.ENABLE, token: null })
    }, 200)
  }

  const isAllowedToClose = mfaProps.step !== MFAStep.BACKUP
  return (
    <Modal
      open={showEnableMFAModal}
      title={{
        text: 'Enable Multi-Factor Authentication',
        icon: KeyIcon,
      }}
      onClose={isAllowedToClose ? () => setShowEnableMFAModal(false) : null}
      customClass='mfa-modal'
    >
      <MFA
        backButtonProps={{
          onClick: isAllowedToClose ? () => setShowEnableMFAModal(false) : null,
          text: 'Close',
        }}
        setMfaProps={setMfaProps}
        mfaProps={mfaProps}
        onBackupAcknowledge={onBackupAcknowledge}
      />
    </Modal>
  )
}
