import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import UiImage from '@components/Common/UiImage'
import { BUCKETS } from '@enums/generics'
import { Organization } from '@typings/organization'
import { redirectToWorkSpace } from '@utils/global-helpers'
import { FC } from 'react'
import { Typography } from '@components/Common/Typography/Typography'
import './OrgModalItem.scss'
import clsx from 'clsx'
import { useUserStore } from '@zustand/useUserStore'

type OrgModalItemProps = {
  orgItem: Organization
  isSSOAuth: boolean
}

export const OrgModalItem: FC<OrgModalItemProps> = ({ orgItem, isSSOAuth }) => {
  const { user, setShowEnableMFAModal } = useUserStore((s) => ({
    user: s.user,
    setShowEnableMFAModal: s.setShowEnableMFAModal,
  }))

  const mfaRequired = !user?.mfa_enabled && orgItem.settings.enforce_mfa && !isSSOAuth

  return (
    <FlexContainer
      className='org-modal-item'
      fullWidth
      justify='space-between'
      align='center'
      gap='var(--s-sm)'
    >
      <FlexContainer
        gap='var(--s-xxxs)'
        align='center'
        className={clsx('org-modal-item__content', { disabled: mfaRequired })}
      >
        {orgItem.logo_url && <UiImage src={`${BUCKETS.orgIcons}/${orgItem.logo_url}`} alt={orgItem.name} />}

        <Typography
          color='var(--black-1)'
          className='org-modal-item__name'
          fontSize='var(--t-medium)'
          fontWeight={500}
        >
          {orgItem.name}
        </Typography>

        <Typography color='var(--blue-3)' className='org-modal-item__workspace' fontSize='var(--t-medium)'>
          {`(${orgItem.workspace.toLowerCase()}.${import.meta.env.VITE_FE_BASE_URL})`}
        </Typography>
      </FlexContainer>

      <ButtonElement
        scaleOnTap
        variant={mfaRequired ? 'secondary' : 'primary'}
        onClick={() => (mfaRequired ? setShowEnableMFAModal(true) : redirectToWorkSpace(orgItem.workspace))}
      >
        {mfaRequired ? 'Enable MFA' : 'Enter'}
      </ButtonElement>
    </FlexContainer>
  )
}
