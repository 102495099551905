import React, { CSSProperties, FC, useMemo } from 'react'

interface IUserCircleProps {
  fullName: string
  color?: string
}

const MAX_CHARTS = 2

export const UserCircle: FC<IUserCircleProps> = ({ fullName, color }) => {
  const initials = useMemo(() => {
    if (!fullName) return ''

    const initials = fullName
      .split(' ')
      .map((el) => el[0])
      .join('')
      .toUpperCase()

    return initials?.slice(0, MAX_CHARTS)
  }, [fullName])

  return (
    <div className='user-circle' style={{ '--clr': color } as CSSProperties}>
      <span>{initials}</span>
    </div>
  )
}
