import { FC } from 'react'
import { OrgsModal } from './OrgsModal/OrgsModal'
import { AppInitResp } from '@services/apis/organization'

type OrgsWorkspaceModalProps = {
  appInitData: AppInitResp
}

export const OrgsWorkspaceModal: FC<OrgsWorkspaceModalProps> = ({ appInitData }) => {
  const accessibleOrgs = appInitData.organizations

  return (
    <OrgsModal
      accessibleOrgs={[...accessibleOrgs].sort((a, b) =>
        a.settings.enforce_mfa && !b.settings.enforce_mfa ? 1 : -1,
      )}
      isSSOAuth={appInitData.is_sso_auth}
      title='Select Organization Workspace'
      subTitle={
        accessibleOrgs.length > 1
          ? 'Your account is linked with multiple workspaces. Select one to proceed or use the workspace URL directly in the address bar next time.'
          : 'Select an organization workspace to proceed or use the workspace URL directly in the address bar next time.'
      }
    />
  )
}
