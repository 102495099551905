import { CSSProperties, forwardRef } from 'react'
import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment'
import { Input, InputProps } from '@mui/material'
import SearchIcon from '@assets/Common/search-icon.svg?react'
import clsx from 'clsx'

export type InputCSSVariablesProps = {
  borderColor?: string
  borderRadius?: string
  focusedBorderColor?: string
  padding?: string
  background?: string
  color?: string
}

export const DEFAULT_INPUT_STYLES: Record<keyof InputCSSVariablesProps, string> = {
  borderColor: 'var(--grey-10)',
  focusedBorderColor: 'var(--black-1)',
  borderRadius: 'var(--br-md)',
  padding: '6px var(--s-xxs)',
  background: 'var(--grey-12)',
  color: 'var(--blue-4)',
}

type StyledTextFieldProps = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  withStartSearchIcon?: boolean
  adornmentProps?: InputAdornmentProps
  cssVariablesProps?: InputCSSVariablesProps
  noBorder?: boolean
  fadeDisabled?: boolean
} & Omit<InputProps, 'onChange' | 'value'>

const StyledTextField = forwardRef<HTMLInputElement, StyledTextFieldProps>(
  (
    { withStartSearchIcon, adornmentProps, noBorder, cssVariablesProps = {}, fadeDisabled, ...inputProps },
    ref,
  ) => {
    return (
      <Input
        ref={ref}
        disableUnderline
        spellCheck='false'
        autoCorrect='off'
        classes={{
          root: clsx('styled-input__root', { 'no-border': noBorder, 'fade-disabled': fadeDisabled }),
          disabled: 'styled-input__disabled',
          focused: 'styled-input__focused',
          input: 'styled-input__input',
        }}
        startAdornment={
          (withStartSearchIcon || inputProps?.startAdornment) && (
            <InputAdornment
              className='styled-input__adornment'
              position='start'
              {...adornmentProps}
              style={
                { '--adornment-clr': cssVariablesProps.color ?? DEFAULT_INPUT_STYLES.color } as CSSProperties
              }
            >
              {withStartSearchIcon ? <SearchIcon /> : inputProps?.startAdornment}
            </InputAdornment>
          )
        }
        {...inputProps}
        className={`styled-input ${inputProps.className}`}
        style={
          {
            '--border-color': cssVariablesProps.borderColor ?? DEFAULT_INPUT_STYLES.borderColor,
            '--border-color-focused':
              cssVariablesProps.focusedBorderColor ?? DEFAULT_INPUT_STYLES.focusedBorderColor,
            '--border-radius': cssVariablesProps.borderRadius ?? DEFAULT_INPUT_STYLES.borderRadius,
            '--padding': cssVariablesProps.padding ?? DEFAULT_INPUT_STYLES.padding,
            '--background': cssVariablesProps.background ?? DEFAULT_INPUT_STYLES.background,
            '--color': cssVariablesProps.color ?? DEFAULT_INPUT_STYLES.color,
            ...inputProps.style,
          } as CSSProperties
        }
      />
    )
  },
)

export default StyledTextField
