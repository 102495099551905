import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Pseudo from 'i18next-pseudo'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en/default.json'

i18n
  .use(
    new Pseudo({
      enabled: true,
      languageToPseudo: 'pseudo',
      wrapped: true,
    }),
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en': { default: en },
      'en-US': { default: en },
      'pseudo': { default: en },
    },

    fallbackLng: 'en',
    debug: !(import.meta.env.MODE === 'production'),
    defaultNS: ['default'],
    interpolation: {
      escapeValue: false,
    },
    postProcess: ['pseudo'],
    detection: {
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',
    },
    saveMissing: !(import.meta.env.MODE === 'production'),
  })

export default i18n
