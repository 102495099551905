import clsx from 'clsx'
import { FC } from 'react'
import './BoxWrapper.scss'

type BoxWrapperProps = {
  children: React.ReactNode
  boxRef?: React.RefObject<HTMLDivElement>
} & React.HTMLAttributes<HTMLElement>

export const BoxWrapper: FC<BoxWrapperProps> = ({ children, boxRef, ...restProps }) => {
  return (
    <div {...restProps} className={clsx('box-wrapper', restProps?.className)} ref={boxRef}>
      {children}
    </div>
  )
}
