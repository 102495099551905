import { FC } from 'react'
import { useOrgStore } from '@zustand/useOrgStore'
import { OrgsModal } from './OrgsModal/OrgsModal'
import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import KeyIcon from '@assets/SideMenu/credentials-icon.svg?react'
import { useUserStore } from '@zustand/useUserStore'
import { AppInitResp } from '@services/apis/organization'

type OrgMFAEnforcementModalProps = {
  appInitData: AppInitResp
}

export const OrgMFAEnforcementModal: FC<OrgMFAEnforcementModalProps> = ({ appInitData }) => {
  const organization = useOrgStore((state) => state.organization)
  const setShowEnableMFAModal = useUserStore((state) => state.setShowEnableMFAModal)

  const allowedOrgs = appInitData.organizations.filter(
    (org) => org.id !== organization.id && !org.settings.enforce_mfa,
  )

  return (
    <OrgsModal
      accessibleOrgs={allowedOrgs}
      isSSOAuth={appInitData.is_sso_auth}
      title='Multi-Factor Authentication Required'
      subTitle={`${organization.name}'s workspace enforces MFA for all accounts. To gain access you must first enable MFA for your account.`}
      subSubTitle={
        allowedOrgs.length > 0 && 'Or you can still access the following workspaces where MFA is not enforced'
      }
    >
      <ButtonElement
        fullWidth
        variant='secondary'
        iconProps={{ icon: KeyIcon }}
        scaleOnTap
        onClick={() => setShowEnableMFAModal(true)}
      >
        Enable MFA
      </ButtonElement>
    </OrgsModal>
  )
}
