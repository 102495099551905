import React, { FC } from 'react'
import clsx from 'clsx'

interface GlobalLoaderProps {
  loading?: boolean
}

const Loader: FC<GlobalLoaderProps> = ({ loading }) => {
  return (
    <div className={clsx('app-loader', { active: loading })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
