import CloseIcon from '@assets/Common/times.svg?react'
import WarningIcon from '@assets/issues/open-issue.svg?react'
import { IconButtonElement } from '@components/Common/IconButtonElement/IconButtonElement'
import './BannerMsgStrip.scss'
import { FC, SyntheticEvent } from 'react'

interface NotificationStripProps {
  message: string
  showBanner: boolean
  onClick: (e: SyntheticEvent) => void
}

export const BannerMsgStrip: FC<NotificationStripProps> = ({ message, showBanner, onClick }) => {
  return (
    <>
      {showBanner && (
        <div className='banner-strip'>
          <WarningIcon />
          <h6 className='banner-strip__text'>{message}</h6>

          <IconButtonElement onClick={onClick} className='banner-strip__cta'>
            <CloseIcon />
          </IconButtonElement>
        </div>
      )}
    </>
  )
}
