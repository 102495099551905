export const USER_ROLE = {
  admin: 1,
  analyst: 2,
} as const

export type TUserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE]

export const USER_ROLE_TO_LABEL = {
  [USER_ROLE.admin]: 'admin',
  [USER_ROLE.analyst]: 'analyst',
}
