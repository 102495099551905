import { Organization } from '@typings/organization'
import { User } from '@typings/user'

export const getMfaModalNotes = (props: {
  organization: Organization
  user: User
  isCurrentUser: boolean
}) => {
  const { organization, user, isCurrentUser } = props
  const notes = [
    isCurrentUser
      ? 'You will be able to re-enable it later.'
      : 'Users can always re-enable MFA individually.',
  ]

  if (organization.settings.enforce_mfa) {
    const note =
      isCurrentUser && user.mfa_enabled
        ? 'MFA is currently enforced for the organization. Disabling it will restrict your access to the workspace until you re-enable it.'
        : 'MFA is currently enforced for the organization. The user will lose access to the workspace until MFA is re-enabled.'

    notes.unshift(note)
  }

  return notes
}
