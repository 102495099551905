import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import React, { CSSProperties, FC } from 'react'

type TCircularLoaderProps = {
  loading: boolean
  size?: 'medium' | 'large'
  color?: string
  customClass?: string
}

const CircularLoader: FC<TCircularLoaderProps> = ({
  loading,
  size = '',
  color = 'var(--tertiary-1)',
  customClass = '',
}) => {
  return (
    <CircularProgress
      style={{ '--color': color } as CSSProperties}
      classes={{
        root: clsx(`circular-loader ${customClass}`, {
          active: loading,
          medium: size === 'medium',
          large: size === 'large',
        }),
      }}
    />
  )
}

export default CircularLoader
