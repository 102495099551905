import { TUserRole, USER_ROLE } from '../../../enums/users'
import AdminIcon from '@assets/User/admin.svg?react'
import AnalystIcon from '@assets/User/analyst.svg?react'
import { FC } from 'react'
import './UserTypeIcon.scss'

interface IUserTypeIconProps {
  userType: TUserRole
  iconClr?: string
}

export const UserTypeIcon: FC<IUserTypeIconProps> = ({ userType, iconClr }) => {
  const isAdmin = userType === USER_ROLE.analyst

  const color = iconClr ? { color: iconClr } : {}

  return (
    <div className='user-type-icon'>
      {isAdmin ? <AdminIcon style={color} /> : <AnalystIcon style={color} />}
    </div>
  )
}
