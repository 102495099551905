import { FC } from 'react'
import { Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ROUTES } from '@enums/routes'
import { useAppInit } from '@hooks/useAppInit'
import { CustomRoute } from './CustomRoute'
import { ElementLoader } from '@components/Common/ElementLoader/ElementLoader'
import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { useUserStore } from '@zustand/useUserStore'

const Auth = loadable(() => import('@pages/Auth/components/AuthLayout'))

export const AppRouter: FC = () => {
  const { isSuccess, data: appInitData } = useAppInit()
  const isAuthenticated = useUserStore((s) => s.isAuthenticated)

  return (
    <Switch>
      <CustomRoute
        path={ROUTES.AUTH}
        component={<Auth />}
        guardProps={{ allowEntry: !isAuthenticated, redirectTo: ROUTES.DASHBOARD }}
      />

      {isSuccess ? <AuthenticatedRoutes appInitData={appInitData} /> : <ElementLoader />}
    </Switch>
  )
}
