import { shallow } from 'zustand/shallow'
import { boolStrToBool, isObjEmpty } from '@utils/global-helpers'
import { getLS, isNill } from '@utils/global-helpers'
import { Organization } from '@typings/organization'
import { createWithEqualityFn } from 'zustand/traditional'
import { ELEMENT_SECURITY_ORG_ID, ORG_SETTING, TOrgSetting } from '@enums/organizations'
import { createComputed } from 'zustand-computed'

type OrgStoreState = {
  organization: Organization
  setOrganization: (org: Organization) => void
  getOrgSetting: (key: TOrgSetting, org: Organization) => any
  reset: () => void
}

const orgStoreInitialState = {
  organization: null,
}

const computed = createComputed((s: OrgStoreState) => {
  const org = s.organization

  const showReviewTab = () => {
    const lsEnabled = getLS(ORG_SETTING.review_enabled)
    if (!isNill(lsEnabled)) return !!lsEnabled

    const orgReviewVal = boolStrToBool(s.getOrgSetting(ORG_SETTING.review_enabled, org))

    if (orgReviewVal) return orgReviewVal
  }

  const allowDynamicRoutes = org?.id === ELEMENT_SECURITY_ORG_ID // Always allow Element Security to see and enter CredsBreach routes

  return {
    showReviewTab: showReviewTab(),
    allowCredsBreachRoute:
      allowDynamicRoutes || boolStrToBool(s.getOrgSetting(ORG_SETTING.enable_creds_breach, org)),
  }
})

export const useOrgStore = createWithEqualityFn<OrgStoreState>()(
  computed((set) => ({
    ...orgStoreInitialState,

    // Setters
    setOrganization: (org: Organization) => set({ organization: org }),

    // Actions
    getOrgSetting: (settingKey, org) => {
      if (isObjEmpty(org) || !org) return

      return org.settings[settingKey]
    },

    reset: () => set(orgStoreInitialState),
  })),
  shallow,
)
