export const defaultDoughnutProps = (moreThanOneItem: boolean) => {
  return {
    borderColor: 'transparent',
    borderWidth: 0,
    spacing: moreThanOneItem ? 7 : 0,
    borderRadius: moreThanOneItem ? 2 : 0,
  }
}

export const defaultLineGraphProps = { borderWidth: 1.8, radius: 2.3, lineTension: 0.3 }
