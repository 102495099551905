import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import './CustomLink.scss'

type ConditionalProps = { text: string; children?: never } | { children: React.ReactNode; text?: never }

export type ICustomLinkProps = {
  to: LinkProps['to'] | string

  externalLink?: boolean
  replaceCurrentTab?: boolean
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  fitContent?: boolean
  underline?: boolean
  blueText?: boolean
} & ConditionalProps &
  React.HTMLAttributes<HTMLAnchorElement>

export const CustomLink = forwardRef<HTMLAnchorElement, ICustomLinkProps>(
  (
    {
      to,
      externalLink,
      replaceCurrentTab,
      text,
      disabled,
      className,
      style,
      children,
      blueText,
      fitContent,
      underline,
      ...rest
    },
    ref,
  ) => {
    const props = {
      className: clsx('custom-link', {
        [className]: className,
        underline,
        disabled,
        blue: blueText,
        fit: fitContent,
      }),
      style,
      ...rest,
    }

    return (
      <>
        {externalLink ? (
          <a
            ref={ref}
            href={to as string}
            target={replaceCurrentTab ? null : '_blank'}
            rel='noreferrer'
            {...props}
          >
            {children ? children : text}
          </a>
        ) : (
          <Link ref={ref} to={to} {...props}>
            {children ? children : text}
          </Link>
        )}
      </>
    )
  },
)
