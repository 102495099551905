import React, { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import ErrorBoundaryComp from '@components/Common/ErrorBoundaryComp'
import { ErrorBoundary } from 'react-error-boundary'

type CustomRouteProps = {
  component: React.ReactNode
  guardProps?: {
    allowEntry: boolean
    redirectTo: string
  }
} & Omit<RouteProps, 'component'>

export const CustomRoute: FC<CustomRouteProps> = ({ component, guardProps, ...routeProps }) => {
  if (guardProps && !guardProps.allowEntry) return <Redirect to={guardProps.redirectTo} />

  return (
    <Route {...routeProps}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryComp as FC}>{component}</ErrorBoundary>
    </Route>
  )
}
