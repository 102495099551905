import ElementLogo from '@assets/Common/element-logo-full.svg?react'
import ConstructionIcon from '@assets/Common/construction.svg?react'
import { CustomRoute } from '@routing/CustomRoute'
import { Redirect, Switch } from 'react-router-dom'
import { ROUTES } from '@enums/routes'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { FC } from 'react'
import './Maintenance.scss'

const Maintenance: FC = () => {
  return (
    <FlexContainer className='maintenance' align='center' justify='center' fullWidth>
      <FlexContainer
        direction='column'
        align='center'
        justify='center'
        className='maintenance__container'
        fullWidth
      >
        <FlexContainer className='maintenance__content' align='flex-start' justify='space-between' fullWidth>
          <FlexContainer className='maintenance__msg' direction='column' align='flex-start'>
            <ElementLogo className='maintenance__logo' />

            <h1 className='maintenance__msg--title'>Sorry!</h1>

            <span className='maintenance__msg--text'>Taking a quick break for some updates.</span>
            <span className='maintenance__msg--text'>We will be back up, better and stronger, soon!</span>
          </FlexContainer>

          <ConstructionIcon className='maintenance__icon' />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

export const MaintenanceApp = () => (
  <Switch>
    <CustomRoute exact path={ROUTES.MAINTENANCE} component={<Maintenance />} />
    <CustomRoute component={<Redirect to={ROUTES.MAINTENANCE} />} path='*' />
  </Switch>
)
