import { FC } from 'react'
import ReportIcon from '@assets/Common/report.svg?react'
import { TopNavButton } from '../TopNav'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'

export const TopNavReport: FC = () => {
  return (
    <>
      <TopNavButton disabled className='top-nav__report'>
        <FlexContainer gap='var(--s-xxxs)'>
          <ReportIcon />

          <span>Generate Report</span>
        </FlexContainer>
      </TopNavButton>
    </>
  )
}
