export const ASSET_TYPE = {
  All: 0,
  Domain: 1,
  Subdomain: 2,
  Certificate: 3,
  IpAsset: 4,
  Cloud: 5,
  Netblock: 6,
} as const

export type TAssetType = (typeof ASSET_TYPE)[keyof typeof ASSET_TYPE]

export const ASSET_TYPE_TO_LABEL = {
  [ASSET_TYPE.IpAsset]: 'IP Address',
  [ASSET_TYPE.Domain]: 'Domain',
  [ASSET_TYPE.Subdomain]: 'Subdomain',
  [ASSET_TYPE.Certificate]: 'Certificate',
  [ASSET_TYPE.Cloud]: 'Cloud Storage',
}

export const ASSET_TYPES_NAME = {
  [ASSET_TYPE.All]: 'all',
  [ASSET_TYPE.IpAsset]: 'ips',
  [ASSET_TYPE.Domain]: 'domains',
  [ASSET_TYPE.Subdomain]: 'subdomains',
  [ASSET_TYPE.Certificate]: 'certificates',
  [ASSET_TYPE.Cloud]: 'cloud',
  [ASSET_TYPE.Netblock]: 'netblocks',
}

export const ASSET_TYPES_NAME_TO_VALUE = {
  all: ASSET_TYPE.All,
  ips: ASSET_TYPE.IpAsset,
  domains: ASSET_TYPE.Domain,
  subdomains: ASSET_TYPE.Subdomain,
  certificates: ASSET_TYPE.Certificate,
  cloud: ASSET_TYPE.Cloud,
  netblocks: ASSET_TYPE.Netblock,
}

export const ASSET_TYPE_TO_COLOR = {
  [ASSET_TYPE.Domain]: '#abd9e9',
  [ASSET_TYPE.Subdomain]: '#6392FF',
  [ASSET_TYPE.IpAsset]: '#0B7360',
  [ASSET_TYPE.Cloud]: '#fdae61',
  [ASSET_TYPE.Certificate]: '#fee090',
}

export const ASSET_STATUS = {
  active: 1,
  review: 2,
  removed: 3,
  archived: 4,
} as const

export type TAssetStatus = (typeof ASSET_STATUS)[keyof typeof ASSET_STATUS]

export const ASSET_STATUS_TO_LABEL = {
  [ASSET_STATUS.active]: 'active',
  [ASSET_STATUS.review]: 'review',
  [ASSET_STATUS.removed]: 'removed',
  [ASSET_STATUS.archived]: 'archived',
}

export const ASSET_STATUS_TO_COLOR = {
  [ASSET_STATUS.active]: '#6DB350',
  [ASSET_STATUS.review]: '#C2C3C5',
  [ASSET_STATUS.removed]: '#DB575E',
  [ASSET_STATUS.archived]: '#F9C74F',
}
