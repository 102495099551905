import { TSnackBarAdditionalData, TSnackBarFinalData } from '@typings/snackbar'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type CommonStoreState = {
  // Snackbar
  snackBarData: TSnackBarFinalData
  setSnackBarData: (data: TSnackBarFinalData) => void
  closeSnackBar: () => void
  toggleSnackBar: (message: string, data?: TSnackBarAdditionalData) => void
}

const commonStoreInitialState = {
  snackBarData: null,
}

export const useCommonStore = createWithEqualityFn<CommonStoreState>((set, get) => {
  return {
    ...commonStoreInitialState,

    setSnackBarData: (data) => set({ snackBarData: data }),
    toggleSnackBar: (message, data) => get().setSnackBarData({ message, data }),
    closeSnackBar: () => get().setSnackBarData(null),
  }
}, shallow)
