export const ELEMENT_SECURITY_ORG_ID = 1

export const ORG_TYPE = {
  Production: 1,
  Demo: 2,
  MSSP: 3,
}

export type TOrgType = (typeof ORG_TYPE)[keyof typeof ORG_TYPE]

export const orgTypes = Object.entries(ORG_TYPE).map(([key, value]) => ({
  label: key,
  value,
}))

export const ORG_SETTING = {
  monitored: 'monitored',
  monitor_new_assets: 'monitor_new_assets',
  port_scanning: 'port_scanning',
  review_enabled: 'review_enabled',
  masscan_custom_ports: 'masscan_custom_ports',
  enable_creds_breach: 'enable_creds_breach',
  auto_close_issues_interval_days: 'auto_close_issues_interval_days',
  enforce_mfa: 'enforce_mfa',
} as const

export type TOrgSetting = (typeof ORG_SETTING)[keyof typeof ORG_SETTING]
