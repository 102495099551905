import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import Loader from '@components/Common/Loader'
import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import StyledTextField from '@components/Common/StyledTextField'
import { Typography } from '@components/Common/Typography/Typography'
import { AuthService } from '@services/apis/auth'
import { extractUIError } from '@utils/global-helpers'
import { useCommonStore } from '@zustand/useCommonStore'
import { FC, useEffect, useRef, useState } from 'react'
import { MFAProps } from '../MFA'
import './VerifyMFA.scss'
import { useUserStore } from '@zustand/useUserStore'

type VerifyMFAProps = {
  mfaToken: string
  setMfaProps: (mfaProps: MFAProps) => void
}

export const VerifyMFA: FC<VerifyMFAProps> = ({ mfaToken, setMfaProps }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [apiErrorMsg, setApiErrorMsg] = useState<string>('')
  const [inputValue, setInputValue] = useState<string[]>(Array.from({ length: 6 }, () => ''))
  const [useBackupCode, setUseBackupCode] = useState<boolean>(false)

  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const setIsAuthenticated = useUserStore((state) => state.setIsAuthenticated)
  const toggleSnackBar = useCommonStore((s) => s.toggleSnackBar)

  const verifyMFA = async () => {
    setLoading(true)
    try {
      await AuthService.verifyMFA({
        mfa_token: mfaToken,
        value: inputValue.join(''),
        is_backup_code: useBackupCode,
      })
      setIsAuthenticated(true)
    } catch (error) {
      console.error(error)
      const msg = extractUIError(error)
      if (msg) return setApiErrorMsg(msg)
      toggleSnackBar('An error occurred while verifying MFA, please re-login and try again')
      setMfaProps(null)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const value = e.target.value
    if (value.length > 1) return

    setInputValue((prev) => {
      const newValues = [...prev]
      newValues[idx] = value
      return newValues
    })

    if (value.length === 1 && idx < 5) {
      inputRefs.current[idx + 1]?.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    if (e.key === 'Backspace' && !inputValue[idx] && idx > 0) {
      inputRefs.current[idx - 1]?.focus()
    }
  }

  const onMfaTypeChange = () => {
    setUseBackupCode((prev) => !prev)
    setInputValue(Array.from({ length: 6 }, () => ''))
  }

  // Auto verify MFA if all inputs are filled
  useEffect(() => {
    if (!useBackupCode && inputValue.every((v) => v)) {
      verifyMFA()
    }
  }, [inputValue])

  return (
    <FlexContainer fullWidth direction='column' className='verify-mfa'>
      <Loader loading={loading} />

      <FlexContainer
        direction='column'
        gap='var(--s-s)'
        style={{ width: 256 }}
        element={'form'}
        onSubmit={(e) => {
          e.preventDefault()
          verifyMFA()
        }}
      >
        {useBackupCode ? (
          <StyledTextField
            fullWidth
            autoFocus
            value={inputValue.join('')}
            onChange={(e) => setInputValue(Array.from(e.target.value))}
          />
        ) : (
          <FlexContainer gap='var(--s-xxs)' fullWidth>
            {inputValue.map((_, idx) => (
              <StyledTextField
                key={idx}
                type='number'
                inputProps={{ style: { textAlign: 'center' } }}
                autoFocus={idx === 0}
                inputRef={(el) => (inputRefs.current[idx] = el)}
                value={inputValue[idx]}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                onChange={(e) => handleInputChange(e, idx)}
              />
            ))}
          </FlexContainer>
        )}

        <Typography
          fontSize='var(--text-size-2)'
          fontWeight={500}
          color='var(--tertiary-1)'
          role='button'
          pointer
          onClick={onMfaTypeChange}
        >
          {useBackupCode ? 'Use Authenticator Code' : 'Use Backup Code'}
        </Typography>

        <FlexContainer direction='column' gap='var(--s-xxs)' fullWidth>
          <ButtonElement
            variant='secondary'
            type='submit'
            fullWidth
            disabled={inputValue.some((v) => !v) || loading}
            scaleOnTap
            iconProps={{ icon: 'checkmark' }}
          >
            Verify
          </ButtonElement>

          <ButtonElement onClick={() => setMfaProps(null)} fullWidth scaleOnTap>
            Back to Login
          </ButtonElement>
        </FlexContainer>

        {apiErrorMsg && (
          <Typography
            fontWeight={500}
            fontSize={'var(--text-size-2)'}
            color='var(--red-3)'
          >{`* ${apiErrorMsg}`}</Typography>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}
