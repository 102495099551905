import clsx from 'clsx'
import React, { ReactNode, forwardRef } from 'react'

type FlexProps = {
  direction?: 'row' | 'column'
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  gap?: string
  fullWidth?: boolean
  className?: string
  style?: React.CSSProperties
  children: ReactNode
  element?: keyof JSX.IntrinsicElements | React.ComponentType<any>
} & React.HTMLAttributes<HTMLElement>

export const FlexContainer = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      direction = 'row',
      justify = 'flex-start',
      align = 'center',
      wrap = 'nowrap',
      gap,
      fullWidth,
      children,
      className = '',
      element = 'div',
      style,
      ...rest
    },
    ref,
  ) => {
    const Element = element as React.ElementType

    return (
      <Element
        className={clsx('flex-container', className)}
        style={{
          display: 'flex',
          flexDirection: direction,
          justifyContent: justify,
          alignItems: align && align,
          flexWrap: wrap,
          gap,
          width: fullWidth && '100%',
          ...style,
        }}
        ref={ref}
        {...rest}
      >
        {children}
      </Element>
    )
  },
)
