import { AuthService } from '@services/apis/auth'
import { FC, useState } from 'react'
import { MFAProps, MFAStep } from '../MFA'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import { copyToClipboard, extractUIError } from '@utils/global-helpers'
import { useCommonStore } from '@zustand/useCommonStore'
import Loader from '@components/Common/Loader'
import { useQuery } from '@tanstack/react-query'
import { BoxWrapper } from '@components/SidePanel/BoxWrapper/BoxWrapper'
import { QUERY_KEYS } from '@enums/queries'
import KeyIcon from '@assets/SideMenu/credentials-icon.svg?react'
import './EnableMFA.scss'
import { StyledSeperatorText } from '@components/Common/StyledSeperatorText/StyledSeperatorText'
import { Typography } from '@components/Common/Typography/Typography'

type EnableMFAProps = {
  mfaProps: MFAProps
  setMfaProps: (props: MFAProps) => void
  onBackupAcknowledge: () => void
  backButtonProps: {
    onClick: () => void
    text: string
  }
}

export const EnableMFA: FC<EnableMFAProps> = ({
  mfaProps,
  setMfaProps,
  onBackupAcknowledge,
  backButtonProps,
}) => {
  const [backupCode, setBackupCode] = useState<string>('')
  const [actionLoading, setActionLoading] = useState<boolean>(false)

  const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)

  const { token, step } = mfaProps
  const mfaDataQuery = useQuery({
    queryKey: [QUERY_KEYS.getEnableMFAData],
    queryFn: async () => await AuthService.getEnableMfaData(token),
    meta: {
      errorCallback: (e) => {
        const msg = extractUIError(e)
        toggleSnackBar(msg ?? 'An error occured while enabling MFA please try again later')
      },
    },
  })

  const onEnableMFA = async () => {
    setActionLoading(true)
    try {
      const data = await AuthService.enableMFA(token, mfaDataQuery.data.mfa_secret)
      setBackupCode(data.mfa_backup_secret)
      setMfaProps({ token, step: MFAStep.BACKUP })
    } catch (error) {
      const msg = extractUIError(error)
      toggleSnackBar(msg ?? 'An error occured while enabling MFA please try again later')
      console.log(error)
    } finally {
      setActionLoading(false)
    }
  }

  const inputValue =
    step === MFAStep.ENABLE
      ? mfaDataQuery.data?.mfa_secret ?? '***********************************'
      : backupCode

  return (
    <FlexContainer
      direction='column'
      align='center'
      justify='center'
      gap='var(--s-xl)'
      className='enable-mfa'
    >
      <Loader loading={actionLoading || mfaDataQuery.isLoading} />

      {step === MFAStep.ENABLE && (
        <div className='enable-mfa__qr'>
          {mfaDataQuery.isSuccess && (
            <img
              alt='QR Code'
              src={`data:image/png;base64,${mfaDataQuery.data.qr_code_image}`}
              className='enable-mfa__qr__img'
            />
          )}
        </div>
      )}

      <FlexContainer direction='column' gap='var(--s-s)' fullWidth className='enable-mfa__content'>
        {step === MFAStep.ENABLE && (
          <StyledSeperatorText>
            <Typography color={'var(--blue-3)'} fontSize={'var(--text-size-2)'}>
              Or enter the code manually into your authenticator app
            </Typography>
          </StyledSeperatorText>
        )}

        <FlexContainer gap='var(--s-xxs)' align='center' fullWidth>
          <BoxWrapper>{inputValue}</BoxWrapper>

          <ButtonElement
            iconProps={{ icon: 'copy' }}
            style={{ height: '36px' }}
            variant='secondary'
            onClick={() => copyToClipboard(inputValue)}
            scaleOnTap
          />
        </FlexContainer>

        <FlexContainer gap='var(--s-xxs)' align='center' fullWidth direction='column'>
          <ButtonElement
            variant='secondary'
            onClick={step === MFAStep.ENABLE ? onEnableMFA : onBackupAcknowledge}
            iconProps={{
              icon: step === MFAStep.ENABLE ? KeyIcon : null,
            }}
            disabled={actionLoading || mfaDataQuery.isLoading}
            fullWidth
            scaleOnTap
          >
            {step === MFAStep.ENABLE ? 'Enable MFA' : 'Continue'}
          </ButtonElement>

          {step !== MFAStep.BACKUP && (
            <ButtonElement onClick={backButtonProps.onClick} fullWidth scaleOnTap>
              {backButtonProps.text}
            </ButtonElement>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}
