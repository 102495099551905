import { ApiJob, ApiJobType } from '@typings/api-job'
import { ApiJobsService } from '@services/apis/api-jobs'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { API_JOB_STATUS, API_JOB_TYPE } from '@enums/api-jobs'
import { QUERY_KEYS } from '@enums/queries'
import { useCommonStore } from '@zustand/useCommonStore'
import { useOrgStore } from '@zustand/useOrgStore'
import { useCallback } from 'react'
import { useApiJobsStore } from '@zustand/useApiJobsStore'

const POLL_RATE = 6000

type IssuesCsvJobParams = { issue_ids: number[] }
type CredsCsvJobParams = { cred_ids: number[] }
type AssetsCsvJobParams = { asset_ids: number[] }
type IntegrationMsgTestParams = { integration_id: number }

export const useApiJobs = () => {
  const organization = useOrgStore((s) => s.organization)
  const toggleSnackBar = useCommonStore((s) => s.toggleSnackBar)

  const {
    createdJobs,
    setCreatedJobs,
    setEnableJobsQuery,
    setHandledJobIds,
    handledJobIds,
    enableJobsQuery,
  } = useApiJobsStore((s) => ({
    createdJobs: s.createdJobs,
    enableJobsQuery: s.enableJobsQuery,
    handledJobIds: s.handledJobIds,
    setCreatedJobs: s.setCreatedJobs,
    setEnableJobsQuery: s.setEnableJobsQuery,
    setHandledJobIds: s.setHandledJobIds,
  }))

  const onJobFinish = async (finishedJob: ApiJob) => {
    const createdJob = createdJobs.find((j) => j.id === finishedJob.id)
    if (!createdJob)
      return toggleSnackBar('An error occurred while handling the task, please try again later.')

    createdJob.onSuccess(finishedJob)
  }

  const { data: jobs = [] } = useQuery({
    queryKey: [QUERY_KEYS.apiJobs, { createdJobs }],
    queryFn: async ({ signal }) => {
      const { jobs } = await ApiJobsService.getJobs({
        orgId: organization.id,
        jobIds: createdJobs.map(({ id }) => id).join(','),
        signal,
      })

      const unhandledSuccessJobIds = []
      const allDone = jobs.every((job, idx) => {
        const isSuccess = job.status === API_JOB_STATUS.SUCCESS
        const isUnhandled = isSuccess && !handledJobIds.includes(job.id)

        if (isUnhandled) {
          unhandledSuccessJobIds.push(job.id)

          if (idx === 0) onJobFinish(job)
          else setTimeout(() => onJobFinish(job), idx * 1750)
        }

        return job.status !== API_JOB_STATUS.PENDING
      })

      if (unhandledSuccessJobIds.length > 0) {
        setHandledJobIds([...handledJobIds, ...unhandledSuccessJobIds])
      }

      if (allDone) setEnableJobsQuery(false)
      return jobs
    },
    placeholderData: keepPreviousData,
    enabled: enableJobsQuery,
    refetchInterval: POLL_RATE,
  })

  // Overloads for different job types
  function startJob(props: {
    jobType: typeof API_JOB_TYPE.ISSUES_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: IssuesCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.CREDS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: CredsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.ALL_ASSETS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>
  function startJob(props: {
    jobType: typeof API_JOB_TYPE.CLOUD_ASSETS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.DOMAINS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.SUBDOMAINS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.CERTIFICATES_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.IPS_CSV
    onJobFinish: (job: ApiJob) => void
    jobParams?: AssetsCsvJobParams
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.DNS_CSV
    onJobFinish: (job: ApiJob) => void
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.VULNS_CSV
    onJobFinish: (job: ApiJob) => void
  }): Promise<void>

  function startJob(props: {
    jobType: typeof API_JOB_TYPE.INTEGRATION_MSG_RETEST
    onJobFinish: (job: ApiJob) => void
    jobParams: IntegrationMsgTestParams
  }): Promise<void>

  // Overloads for different job types

  async function startJob(props: {
    jobType: ApiJobType
    onJobFinish: (job: ApiJob) => void
    jobParams?: Record<string, any>
  }): Promise<void> {
    const { jobType, onJobFinish, jobParams } = props

    try {
      const resp = await ApiJobsService.createJob({
        orgId: organization.id,
        payload: { jobType, jobParams },
      })

      setCreatedJobs([...createdJobs, { id: resp.id, onSuccess: onJobFinish }])
      if (!enableJobsQuery) setEnableJobsQuery(true)
    } catch (error) {
      console.log(error)
      const code = error?.response?.status

      if (code === 429) {
        toggleSnackBar('A pending task already exists for this action, please try again later')
        return
      }

      toggleSnackBar('An error occurred while creating task, please try again later')
    }
  }

  const getIsJobPending = useCallback(
    (jobType: ApiJobType) => {
      return jobs.some((job) => job.type === jobType && job.status === API_JOB_STATUS.PENDING)
    },
    [jobs],
  )

  return { startJob, getIsJobPending, jobs }
}
